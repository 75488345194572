import React from 'react';
import PropTypes from 'prop-types';

function AboutSection({ title, image, children }) {
  return (
  <div className="bg-gray-100">
    <div className="flex items-center flex-col-reverse md:flex-row py-20 max-w-6xl mx-auto">
      <div className="w-full md:w-1/2 px-8">
        <div className="font-serif font-light italic text-3xl text-purple mb-6">
          {title}
        </div>
        {children}
      </div>
      <div className="w-full md:w-1/2 px-8 mb-20 md:mb-0">
        <div className="px-10 lg:px-20">
          <div className="relative">
            <img src={image} alt="" className="relative w-full rounded z-10" />
            <ImageBorder style={{ top: '-36px', bottom: '-36px', left: '-16px' }} />
            <ImageBorder style={{ top: '-16px', bottom: '-16px', right: '-16px' }} />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
AboutSection.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ImageBorder = ({ style }) => (
  <div className={`absolute w-full flex-auto border-2 border-yellow rounded`} style={style}></div>
);
ImageBorder.propTypes = {
  style: PropTypes.object.isRequired
};

export default AboutSection;
