import React from 'react';
import PropTypes from 'prop-types';

import ScriptTag from 'react-script-tag';

function Newsletter({ uid }) {
  return (
    <div className="bg-white">
    <div className="py-20 px-8 container mx-auto">
      <div className="flex justify-center">
        <ScriptTag async data-uid={uid} src={`https://sunny-artisan-2029.ck.page/${uid}/index.js`}>
        </ScriptTag>
      </div>
    </div>
  </div>
  );
}
Newsletter.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default Newsletter;
